import {memo, useEffect, useState} from 'react'
import {HotTable} from '@handsontable/react';
import Handsontable from 'handsontable';
import data from './data.json'
import 'handsontable/dist/handsontable.full.css';
import './App.css'
import {useResizeDetector} from 'react-resize-detector';


const tableData = data.data;

const headCells = Object.keys(tableData[0]);

const renderCurrency = value => {
  return '$ ' + (+value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

const formattedData = tableData.slice(1).map(el => {
  const result = [];
  Object.values(el).forEach(value => {
    const formattedValue = Number.isFinite(value) ? renderCurrency(value) : value;
    result.push(formattedValue);
  })
  return result;
})

const somedata = Handsontable.helper.createSpreadsheetData(12, 5)


const fullData = [headCells, ...formattedData];

function App() {
  const [height, setHeight] = useState(600);
  const [fontSize, setFontSize] = useState(15);
  const [width, setWidth] = useState(1000);
  const [resizeTimeout, setResizeTimeout] = useState(null);

  const {width: wrapperWidth, height: wrapperHeight, ref: wrapperRef} = useResizeDetector();

  useEffect(() => {
    if (wrapperWidth < 200) {
      setFontSize(9);
    } else if (wrapperWidth < 400) {
      setFontSize(10);
    } else if (wrapperWidth < 500) {
      setFontSize(11);
    } else if (wrapperWidth < 600) {
      setFontSize(12);
    } else if (wrapperWidth < 700) {
      setFontSize(13);
    } else if (wrapperWidth < 800) {
      setFontSize(14);
    } else if (wrapperWidth < 1000) {
      setFontSize(15);
    } else if (wrapperWidth < 1200) {
      setFontSize(16);
    } else if (wrapperWidth < 1400) {
      setFontSize(17);
    }

    if (resizeTimeout) clearTimeout(resizeTimeout);
    const timeout = setTimeout(() => {
      setResizeTimeout(null);
    }, 50);
    setResizeTimeout(timeout);

    setWidth(prev => {
      if (wrapperWidth - prev > 50 || wrapperWidth - prev < -50) {
        return wrapperWidth
      }
      return prev
    });

    setHeight(prev => {
      if (wrapperHeight - prev > 50 || wrapperHeight - prev < -50) {
        return wrapperHeight
      }
      return prev
    })
  }, [wrapperWidth, wrapperHeight]);

  useEffect(() => {
    wrapperRef.current.style.fontSize = `${fontSize}px`;
  }, [fontSize, wrapperRef])

  // console.log(!!resizeTimeout)

  return (
    <div className="wrap">
      <div id="hot" ref={wrapperRef}>
        <TableComponent resizeTimeout={!!resizeTimeout}/>
      </div>

    </div>
  );
}

const TableComponent = memo(function ({resizeTimeout}) {
  return (
    <HotTable
      licenseKey="non-commercial-and-evaluation"
      // className="tablee"
      data={fullData}
      colHeaders={true}
      rowHeaders={true}
      height={800}
      // width={2000}
      contextMenu={true}
      manualColumnResize={true}
      manualRowResize={true}
      // cells={function (row, col) {
      //   const cellProperties = {};
      //   const data = this.instance.getData();
      //   const value = data[row][col]
      //
      //
      //   if (Number.isFinite(value)) {
      //     cellProperties.renderer = renderCurrency; // uses function directly
      //   }
      //
      //   return cellProperties;
      // }}
    />
  )
})


export default App;
